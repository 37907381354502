.switch-container {
  position: relative;
}

.switch-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 40px;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden; 
  transition: background-color 0.3s ease; 
}

.switch-button.left.active {
  background: linear-gradient(to right, #4caf50 50%, #dcdcdc 50%);
}

.switch-button.right.active {
  background: linear-gradient(to right, #dcdcdc 50%, #4caf50 50%);
}

.switch-button span {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.plot-container {
  margin: 20px auto; 
  max-width: 80vw; 
}