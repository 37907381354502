.global-options-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    margin-top: -10px;
    padding-top: 0;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    border-top: none;
    border: 1px solid transparent;
    z-index: -1;
  }

   

  .form-container {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .label {
    font-size: 14px;
    margin-bottom: 8px;
    color: #333;
  }
  
  .input-field {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .error-message {
    color: #e74c3c;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .custom-tooltip {
    font-size: 14px;
    background-color: #333;
    color: #fff;
  }

.checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .checkbox-field {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #555; 
    border-radius: 6px; 
    margin-right: 12px; 
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .checkbox-field:checked {
    background-color: #4CAF50; 
    border-color: #4CAF50; 
  }
  
  .checkbox-field:after {
    content: '\2713'; 
    color: #fff; 
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s;
  }
  
  .checkbox-field:checked:after {
    transform: translate(-50%, -50%) scale(1);
  }
  
  .checkbox-label {
    color: #333; 
    display: flex;
    align-items: center;
  }
  
  
  .checkbox-group.disabled {
    opacity: 0.6; 
    pointer-events: none; 
  }
  
  .info-icon {
    position: relative;
    cursor: pointer;
    margin-left: 15px; 
  }

  .info-icon:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    opacity: 0.9;
    z-index: 1;
  }

  .compute-button {
    background-color: #4CAF50; 
    color: white;
    padding: 15px 20px; 
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
  }