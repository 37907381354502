.income-data-container {
    margin-top: 20px;
}

.income-data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border: 2px solid black; 
}

.income-data-table th, .income-data-table td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.income-data-table th {
    background-color: #f5f5f5;
    color: #333;
}
